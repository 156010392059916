// Reference: https://github.com/sindresorhus/html-tags/blob/v4.0.0/html-tags.json

// We purposefully exclude some tags and include them in the disallowed array to
// ensure not to allow tags like `script`, and other embeddable content.

/** Array of valid HTML tags */
export const mdcAllowedTags = [
  'a',
  'abbr',
  'address',
  'area',
  'article',
  'aside',
  // 'audio', // Important: Do not include
  'b',
  'base',
  'bdi',
  'bdo',
  'blockquote',
  'body',
  'br',
  'button',
  // 'canvas', // Important: Do not include
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  'data',
  'datalist',
  'dd',
  'del',
  'details',
  'dfn',
  'dialog',
  'div',
  'dl',
  'dt',
  'em',
  // 'embed', // Important: Do not include
  'fieldset',
  'figcaption',
  'figure',
  'footer',
  // 'form', // Important: Do not include
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  // 'head', // Important: Do not include
  'header',
  'hgroup',
  'hr',
  'html',
  'i',
  // 'iframe', // Important: Do not include; use `ContentIframe` MDC component instead.
  // 'img', // Important: Do not include; use `Image` MDC component instead.
  'input',
  'ins',
  'kbd',
  'label',
  'legend',
  'li',
  'link',
  'main',
  'map',
  'mark',
  'math',
  'menu',
  'menuitem',
  'meta',
  'meter',
  'nav',
  // 'noscript', // Important: Do not include
  // 'object', // Important: Do not include
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'param',
  'picture',
  'pre',
  'progress',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  'ruby',
  's',
  'samp',
  // 'script', // Important: Do not include
  'search',
  'section',
  'select',
  'slot',
  'small',
  'source',
  'span',
  'strong',
  // 'style', // Important: Do not include
  'sub',
  'summary',
  'sup',
  'svg',
  'table',
  'tbody',
  'td',
  // 'template', // Important: Do not include
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'title',
  'tr',
  'track',
  'u',
  'ul',
  'var',
  // 'video', // Important: Do not include
  'wbr',
]

// These tags are likely also specifically excluded from the allowed array
// but we want to ensure they always return 'div'.

/** Array of HTML tags we explicitly do not want to allow */
export const mdcDisallowedTags = [
  'audio',
  'canvas',
  'embed',
  'form',
  'head',
  'iframe',
  'img',
  'noscript',
  'object',
  'script',
  'style',
  'template',
  'video',
]


/**
 * Returns a valid HTML tag from the provided tag string.
 *
 * This function checks if the provided tag is empty, undefined, or not in the predefined list of allowed tags.
 * If any of these conditions are met, it returns 'div' as a default tag.
 * Otherwise, it returns the provided tag in lowercase.
 *
 * @param {string} tag - The tag string to validate and return.
 * @returns {string} The validated tag string or 'div' if the tag is invalid.
 *
 * @example
 * ```typescript
 * getMdcTag('span'); // Returns 'span'
 * getMdcTag(''); // Returns 'div'
 * getMdcTag('invalidTag'); // Returns 'div'
 * ```
 */
export const getMdcTag = (tag: string): string => {
  // Check if tag is empty or undefined, not a string, or not in the list of allowed tags
  if (!tag || typeof tag !== 'string' || tag.trim() === '' || !mdcAllowedTags.includes(tag.toLowerCase()) || !!mdcDisallowedTags.includes(tag.toLowerCase())) {
    return 'div' // Return 'div' if conditions are met
  }
  // Otherwise, return the provided tag if valid
  return tag.toLowerCase()
}
